import React from "react";
import { usePermitsByAquiferGroup } from "../hooks/usePermitsByAquiferGroup";
import { createControls, generateCSV } from "../utils";
import PieChart from "../charts/PieChart";

const PermitsByAquiferGroupChart = () => {
  const { data, isLoading, error, fiscalYears, selectedYear, setSelectedYear } =
    usePermitsByAquiferGroup();

  const controls = createControls(
    "Fiscal Year",
    selectedYear,
    (e) => setSelectedYear(e.target.value),
    fiscalYears
  );

  const labels = data.map((item) => item.aquifer_group_name);
  const chartData = data.map((item) => item.total_permitted_gallons);

  const handleDownloadCSV = () =>
    generateCSV(
      ["Aquifer Group", "Total Gallons"],
      data.map((item) => [
        item.aquifer_group_name,
        item.total_permitted_gallons,
      ])
    );

  return (
    <PieChart
      title="Permitted Gallons by Aquifer"
      data={chartData}
      labels={labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default PermitsByAquiferGroupChart;
