import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS } from "../constants";

export const usePermitsByTier = () => {
  const [selectedYear, setSelectedYear] = useState(null);

  const { data, isLoading, error } = useQuery(
    "permits-by-tier",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-dashboard-permits-by-tier`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );

  const fiscalYears = useMemo(
    () =>
      data
        ? Array.from(new Set(data.map((item) => item.fiscal_year))).sort(
            (a, b) => b - a
          )
        : [],
    [data]
  );

  useEffect(() => {
    if (fiscalYears.length && !selectedYear) {
      setSelectedYear(fiscalYears[0]);
    }
  }, [fiscalYears, selectedYear]);

  const filteredData = useMemo(
    () =>
      data ? data.filter((item) => item.fiscal_year === selectedYear) : [],
    [data, selectedYear]
  );

  const sortedData = useMemo(
    () =>
      filteredData.sort(
        (a, b) => b.total_permitted_gallons - a.total_permitted_gallons
      ),
    [filteredData]
  );

  return {
    data: sortedData,
    isLoading,
    error,
    fiscalYears,
    selectedYear,
    setSelectedYear,
  };
};
