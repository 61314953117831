import React from "react";
import { createControls, generateCSV } from "../utils";
import { usePermitsByTier } from "../hooks/usePermitsByTier";
import PieChart from "../charts/PieChart";

const PermitsByTierChart = () => {
  const { data, isLoading, error, fiscalYears, selectedYear, setSelectedYear } =
    usePermitsByTier();

  const controls = createControls(
    "Fiscal Year",
    selectedYear,
    (e) => setSelectedYear(e.target.value),
    fiscalYears
  );

  const labels = data.map((item) => item.permit_tier_name);
  const chartData = data.map((item) => item.total_permitted_gallons);

  const handleDownloadCSV = () =>
    generateCSV(
      ["Permit Tier", "Total Gallons"],
      data.map((item) => [item.permit_tier_name, item.total_permitted_gallons])
    );

  return (
    <PieChart
      title="Permitted Gallons by Permit Tier"
      data={chartData}
      labels={labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default PermitsByTierChart;
