import React from "react";
import { useWellCounts } from "../hooks/useWellCounts";
import { createControls, generateCSV } from "../utils";
import PieChart from "../charts/PieChart";

const WellCountSummaryChart = () => {
  const { data, isLoading, error, selectedCategory, setSelectedCategory } =
    useWellCounts();

  const categories = [
    { value: "total_well_count", label: "Total Well Count" },
    { value: "edwards_well_count", label: "Edwards Well Count" },
    { value: "trinity_well_count", label: "Trinity Well Count" },
    { value: "other_well_count", label: "Other Well Count" },
  ];

  const controls = createControls(
    "Well Count Category",
    selectedCategory,
    (e) => setSelectedCategory(e.target.value),
    categories
  );

  const labels = data.map((item) => item.summary_category);
  const chartData = data.map((item) => item[selectedCategory]);

  const handleDownloadCSV = () =>
    generateCSV(
      ["Summary Category", "Well Count"],
      data.map((item) => [item.summary_category, item[selectedCategory]])
    );

  return (
    <PieChart
      title="Non-Exempt Well Counts by Category"
      data={chartData}
      labels={labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default WellCountSummaryChart;
