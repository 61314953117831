import { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS } from "../constants";
import { generateCSV } from "../utils";

export const useMonthlyPumpageTracking = () => {
  const [filters, setFilters] = useState({
    aq_group_summary_name: [],
    permit_tier_name: [],
    permit_status_desc: [],
    initialized: false,
  });

  const {
    data: rawData,
    isLoading,
    error,
  } = useQuery(
    "monthly-pumpage-tracking",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-dashboard-tri-bar-chart`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );

  const allValues = useMemo(() => {
    if (!rawData) {
      return {
        aqGroups: [],
        permitTiers: [],
        permitStatusDescs: [],
      };
    }

    const aqGroups = Array.from(
      new Set(rawData.map((item) => item.aq_group_summary_name))
    ).filter(Boolean);

    const permitTiers = Array.from(
      new Set(rawData.map((item) => item.permit_tier_name))
    ).filter(Boolean);

    const permitStatusDescs = Array.from(
      new Set(rawData.map((item) => item.permit_status_desc))
    ).filter(Boolean);

    return {
      aqGroups,
      permitTiers,
      permitStatusDescs,
    };
  }, [rawData]);

  useEffect(() => {
    if (
      rawData &&
      !filters.initialized &&
      allValues.aqGroups.length > 0 &&
      allValues.permitTiers.length > 0 &&
      allValues.permitStatusDescs.length > 0
    ) {
      setFilters({
        aq_group_summary_name: allValues.aqGroups,
        permit_tier_name: allValues.permitTiers,
        permit_status_desc: allValues.permitStatusDescs,
        initialized: true,
      });
    }
  }, [rawData, allValues, filters.initialized]);

  const filteredData = useMemo(() => {
    if (!rawData || !filters.initialized) return [];

    return rawData.filter((item) => {
      return (
        filters.aq_group_summary_name.includes(item.aq_group_summary_name) &&
        filters.permit_tier_name.includes(item.permit_tier_name) &&
        filters.permit_status_desc.includes(item.permit_status_desc)
      );
    });
  }, [rawData, filters]);

  const chartData = useMemo(() => {
    if (!filteredData.length) return { labels: [], datasets: [], raw: [] };

    const groupedData = filteredData.reduce((acc, item) => {
      const mo = item.mo;
      if (!acc[mo]) {
        acc[mo] = { ...item };
      } else {
        acc[mo] = {
          ...acc[mo],
          monthly_permitted_volume:
            acc[mo].monthly_permitted_volume + item.monthly_permitted_volume,
          reduced_monthly_volume:
            acc[mo].reduced_monthly_volume + item.reduced_monthly_volume,
          actual_production: acc[mo].actual_production + item.actual_production,
          exceedance_volume: acc[mo].exceedance_volume + item.exceedance_volume,
          annual_permitted_volume:
            acc[mo].annual_permitted_volume + item.annual_permitted_volume,
        };
      }
      return acc;
    }, {});

    const sortedData = Object.values(groupedData).sort((a, b) => {
      if (a.c_year !== b.c_year) {
        return a.c_year - b.c_year;
      }
      return a.c_month - b.c_month;
    });

    const labels = sortedData.map((item) => item.mo);
    const datasets = [
      {
        type: "bar",
        label: "Permitted",
        data: sortedData.map((item) => item.monthly_permitted_volume),
        backgroundColor: "#888888",
      },
      {
        type: "bar",
        label: "Reduced",
        data: sortedData.map((item) => item.reduced_monthly_volume),
        backgroundColor: "#FFB84D",
      },
      {
        type: "bar",
        label: "Actual",
        data: sortedData.map((item) => item.actual_production),
        backgroundColor: "#4285F4",
      },
      {
        type: "bar",
        label: "Exceedance",
        data: sortedData.map((item) => item.exceedance_volume),
        backgroundColor: "#FF0000",
      },
      {
        type: "line",
        label: "Annual Permitted",
        data: sortedData.map((item) => item.annual_permitted_volume),
        borderColor: "#000000",
        backgroundColor: "#000000",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        hidden: true,
      },
    ];

    return {
      labels,
      datasets,
      raw: sortedData,
    };
  }, [filteredData]);

  const downloadCSV = useCallback(() => {
    if (!chartData.raw.length) return;

    const csvHeaders = [
      "Fiscal Year",
      "Month-Year",
      "Drought Stage",
      "Permitted Gallons",
      "Target Gallons",
      "Actual Pumpage",
      "Gallons of Exceedance",
      "Target Reduction Amount",
      "Count of Exceedances",
      "Count of Permits in Query",
    ];

    const csvData = chartData.raw.map((item) => [
      item.fiscal_year,
      item.mo,
      item.drought_stage,
      item.monthly_permitted_volume,
      item.reduced_monthly_volume,
      item.actual_production,
      item.exceedance_volume,
      item.monthly_permit_reduction,
      item.exceedance_count,
      item.permits_count,
    ]);

    generateCSV(csvHeaders, csvData, "monthly_pumpage_tracking.csv");
  }, [chartData.raw]);

  return {
    data: chartData,
    isLoading,
    error,
    filters,
    setFilters,
    allValues,
    downloadCSV,
  };
};
