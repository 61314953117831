import React from "react";
import { usePermitsByPermitStatus } from "../hooks/usePermitsByPermitStatus";
import { createControls, generateCSV } from "../utils";
import PieChart from "../charts/PieChart";

const PermitsByPermitStatusChart = () => {
  const { data, isLoading, error, fiscalYears, selectedYear, setSelectedYear } =
    usePermitsByPermitStatus();

  const controls = createControls(
    "Fiscal Year",
    selectedYear,
    (e) => setSelectedYear(e.target.value),
    fiscalYears
  );

  const labels = data.map((item) => item.permit_status_desc);
  const chartData = data.map((item) => item.total_permitted_gallons);

  const handleDownloadCSV = () =>
    generateCSV(
      ["Permit Status", "Total Gallons"],
      data.map((item) => [
        item.permit_status_desc,
        item.total_permitted_gallons,
      ])
    );

  return (
    <PieChart
      title="Permitted Gallons by Permit Status"
      data={chartData}
      labels={labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default PermitsByPermitStatusChart;
