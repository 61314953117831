import { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS } from "../constants";
import { generateCSV } from "../utils";

export const usePermittedVolumesOverTime = () => {
  const [filters, setFilters] = useState({
    permit_status_group_name: [],
    type_group: [],
    aq_group_summary_name: [],
    initialized: false,
  });

  const {
    data: rawData,
    isLoading,
    error,
  } = useQuery(
    "permitted-volumes-over-time",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-dashboard-permitted-volumes-over-time`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );

  const allValues = useMemo(() => {
    if (!rawData) {
      return {
        permitStatusOptions: [],
        typeGroupOptions: [],
        aquiferGroupOptions: [],
      };
    }

    const permitStatusOptions = Array.from(
      new Set(rawData.map((item) => item.permit_status_group_name))
    );
    const typeGroupOptions = Array.from(
      new Set(rawData.map((item) => item.type_group))
    );
    const aquiferGroupOptions = Array.from(
      new Set(rawData.map((item) => item.aq_group_summary_name))
    );

    return {
      permitStatusOptions,
      typeGroupOptions,
      aquiferGroupOptions,
    };
  }, [rawData]);

  useEffect(() => {
    if (
      rawData &&
      !filters.initialized &&
      allValues.permitStatusOptions.length > 0 &&
      allValues.typeGroupOptions.length > 0 &&
      allValues.aquiferGroupOptions.length > 0
    ) {
      setFilters({
        permit_status_group_name: allValues.permitStatusOptions,
        type_group: allValues.typeGroupOptions,
        aq_group_summary_name: allValues.aquiferGroupOptions,
        initialized: true,
      });
    }
  }, [rawData, allValues, filters.initialized]);

  const filteredData = useMemo(() => {
    if (!rawData || !filters.initialized) return [];
    return rawData.filter(
      (item) =>
        filters.permit_status_group_name.includes(
          item.permit_status_group_name
        ) &&
        filters.type_group.includes(item.type_group) &&
        filters.aq_group_summary_name.includes(item.aq_group_summary_name)
    );
  }, [rawData, filters]);

  const chartData = useMemo(() => {
    if (!filteredData.length) return { labels: [], datasets: [], raw: [] };

    const dataByYear = filteredData.reduce((acc, item) => {
      const year = item.fiscal_year;
      const volume = item.total_authorized_volume_gallons;

      if (!acc[year]) {
        acc[year] = { year, volume: 0 };
      }

      acc[year].volume += volume;

      return acc;
    }, {});

    const aggregatedArray = Object.values(dataByYear).sort(
      (a, b) => a.year - b.year
    );

    const labels = aggregatedArray.map((item) => item.year.toString());
    const dataset = {
      label: "Total Authorized Volume",
      data: aggregatedArray.map((item) => item.volume),
      backgroundColor: "rgba(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    };

    return {
      labels,
      datasets: [dataset],
      raw: aggregatedArray,
    };
  }, [filteredData]);

  const downloadCSV = useCallback(() => {
    if (!chartData.raw.length) return;

    const csvHeaders = ["Year", "Total Authorized Volume (MGal)"];
    const csvData = chartData.raw.map((item) => [item.year, item.volume]);
    generateCSV(csvHeaders, csvData, "permitted_volumes_over_time.csv");
  }, [chartData.raw]);

  return {
    data: chartData,
    isLoading,
    error,
    filters,
    setFilters,
    allValues,
    downloadCSV,
  };
};
