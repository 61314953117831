import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
} from "@material-ui/core";
import { useMonthlyPumpageTracking } from "../hooks/useMonthlyPumpageTracking";
import BarChart from "../charts/BarChart";

const MonthlyPumpageTrackingChart = () => {
  const {
    data,
    isLoading,
    error,
    filters,
    setFilters,
    allValues,
    downloadCSV,
  } = useMonthlyPumpageTracking();

  const handleFilterChange = (filterName) => (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: event.target.value,
    }));
  };

  const controls = (
    <>
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Aquifer</InputLabel>
          <Select
            multiple
            value={filters.aq_group_summary_name || []}
            onChange={handleFilterChange("aq_group_summary_name")}
            label="Aquifer"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.aqGroups.map((val) => (
              <MenuItem key={val} value={val}>
                <Checkbox
                  checked={filters.aq_group_summary_name.includes(val)}
                />
                <ListItemText primary={val} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Tier</InputLabel>
          <Select
            multiple
            value={filters.permit_tier_name || []}
            onChange={handleFilterChange("permit_tier_name")}
            label="Tier"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.permitTiers.map((val) => (
              <MenuItem key={val} value={val}>
                <Checkbox checked={filters.permit_tier_name.includes(val)} />
                <ListItemText primary={val} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            value={filters.permit_status_desc || []}
            onChange={handleFilterChange("permit_status_desc")}
            label="Status"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.permitStatusDescs.map((val) => (
              <MenuItem key={val} value={val}>
                <Checkbox checked={filters.permit_status_desc.includes(val)} />
                <ListItemText primary={val} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );

  const chartOptions = {
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            if (!tooltipItems.length) return [];
            const dataIndex = tooltipItems[0].dataIndex;
            const rawRow = data.raw[dataIndex] || {};
            return [
              `${rawRow.mo || "N/A"}`,
              `Drought Stage: ${rawRow.drought_stage || "N/A"}`,
            ];
          },
          label: (tooltipItem) => {
            const dataIndex = tooltipItem.dataIndex;
            const rawRow = data.raw[dataIndex] || {};
            const dsLabel = tooltipItem.dataset.label;

            const seriesLabels = {
              Permitted: `Permitted Gallons: ${(
                rawRow.monthly_permitted_volume || 0
              ).toLocaleString()}`,
              Reduced: `Target Gallons: ${(
                rawRow.reduced_monthly_volume || 0
              ).toLocaleString()}`,
              Actual: `Actual Pumpage: ${(
                rawRow.actual_production || 0
              ).toLocaleString()}`,
              Exceedance: `Gallons of Exceedance: ${(
                rawRow.exceedance_volume || 0
              ).toLocaleString()}`,
            };

            if (seriesLabels[dsLabel]) {
              return seriesLabels[dsLabel];
            }

            return "";
          },
          footer: (tooltipItems) => {
            if (!tooltipItems.length) return [];
            const dataIndex = tooltipItems[0].dataIndex;
            const rawRow = data.raw[dataIndex] || {};

            const footerFields = [
              `⚫ Annual Permitted Volume: ${(
                rawRow.annual_permitted_volume || 0
              ).toLocaleString()}`,
              `Target Reduction Amount: ${(
                rawRow.monthly_permit_reduction || 0
              ).toLocaleString()}`,
              `Count of Exceedances: ${rawRow.exceedance_count || 0}`,
              `Count of Permits in Query: ${rawRow.permits_count || 0}`,
            ];

            return footerFields;
          },
        },
      },
    },
  };

  return (
    <BarChart
      title="Monthly Pumpage Tracking (MGal)"
      datasets={data.datasets}
      labels={data.labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={downloadCSV}
      chartOptions={chartOptions}
    />
  );
};

export default MonthlyPumpageTrackingChart;
