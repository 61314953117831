import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS } from "../constants";

export const useLateMeterReadings = () => {
  return useQuery(
    "late-meter-readings",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-dashboard-late-meter-readings`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );
};
