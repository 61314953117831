const pumpage = {
  name: "pumpage",
  ndx: 5,
  field: "ndx",
  label: "Pumpage",
  endpoint: "q-d-data-pumpage",
  icon: "ShutterSpeed",
  inputs: [
    {
      type: "checkbox",
      label: "Associated Wells",
      field: "associated_wells",
      formName: "associatedWells",
      defaultFormValue: true,
      excludeFields: ["associated_wells"],
    },
    {
      type: "search",
      label: "Pumpage",
      valueField: "ndx",
      displayField: "ui_label",
      tooltipField: "calculated_gallons",
      formName: "pumpage",
      endpoint: "query-and-download/q-d-data-pumpage",
      defaultFormValue: [],
      queryParameter: true,
      fuseKeys: ["ui_label", "associated_wells"],
    },
  ],
};

export default pumpage;
