const meters = {
  name: "meters",
  ndx: 4,
  field: "meter_ndx",
  label: "Meters",
  endpoint: "q-d-data-meters",
  icon: "Speed",
  inputs: [
    {
      type: "tristateCheckbox",
      label: "Is Active Permit",
      valueField: "is_active_permit",
      formName: "isActivePermit",
      defaultFormValue: null,
    },
    {
      type: "checkbox",
      label: "Associated Wells",
      field: "associated_wells",
      formName: "associatedWells",
      defaultFormValue: true,
      excludeFields: ["associated_wells"],
    },
    {
      type: "checkbox",
      label: "Associated Permits",
      field: "associated_permits",
      formName: "associatedPermits",
      defaultFormValue: true,
      excludeFields: ["associated_permits"],
    },
    {
      type: "search",
      label: "Meters",
      valueField: "meter_ndx",
      displayField: "meter_id",
      tooltipField: "meter_id",
      formName: "meters",
      endpoint: "query-and-download/q-d-data-meters",
      defaultFormValue: [],
      queryParameter: true,
      fuseKeys: [
        "meter_id",
        "owner_email",
        "associated_wells",
        "associated_permits",
      ],
    },
  ],
};

export default meters;
