const owners = {
  name: "owners",
  ndx: 3,
  field: "rolo_ndx",
  label: "Owners",
  endpoint: "q-d-data-owners",
  icon: "Person",
  inputs: [
    {
      type: "tristateCheckbox",
      label: "Is Permittee",
      valueField: "is_permitee",
      formName: "isPermitee",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "Is Alternate Contact",
      valueField: "is_alternate_contact",
      formName: "isAlternateContact",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "Is Billing Contact",
      valueField: "is_billing_contact",
      formName: "isBillingContact",
      defaultFormValue: null,
    },
    {
      type: "tristateCheckbox",
      label: "Is Meter Contact",
      valueField: "is_meter_contact",
      formName: "isMeterContact",
      defaultFormValue: null,
    },
    {
      type: "checkbox",
      label: "Owner Address",
      field: "owner_address",
      formName: "ownerAddress",
      defaultFormValue: true,
      excludeFields: ["owner_address"],
    },
    {
      type: "checkbox",
      label: "Owner Email",
      field: "owner_email",
      formName: "ownerEmail",
      defaultFormValue: true,
      excludeFields: ["owner_email"],
    },
    {
      type: "checkbox",
      label: "Owner Phone",
      field: "owner_phone",
      formName: "ownerPhone",
      defaultFormValue: true,
      excludeFields: ["owner_phone"],
    },
    {
      type: "checkbox",
      label: "Associated Wells",
      field: "associated_wells",
      formName: "associatedWells",
      defaultFormValue: true,
      excludeFields: ["associated_wells"],
    },
    {
      type: "checkbox",
      label: "Associated Permits",
      field: "associated_permits",
      formName: "associatedPermits",
      defaultFormValue: true,
      excludeFields: ["associated_permits"],
    },
    {
      type: "search",
      label: "Owners",
      valueField: "rolo_ndx",
      displayField: "owner_name",
      tooltipField: "owner_name",
      formName: "owners",
      endpoint: "query-and-download/q-d-data-owners",
      defaultFormValue: [],
      queryParameter: true,
      fuseKeys: [
        "owner_name",
        "owner_email",
        "associated_wells",
        "associated_permits",
      ],
    },
  ],
};

export default owners;
