import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { schemeSet3 } from "d3-scale-chromatic";

export const generateColorScale = (numColors) => {
  return Array.from(
    { length: numColors },
    (_, i) => schemeSet3[i % schemeSet3.length]
  );
};

export const applyOpacity = (color, opacity) => {
  // If color is already in rgba format
  if (color.startsWith("rgba")) {
    return color.replace(/rgba\((.+?),\s*[\d.]+\)/, `rgba($1, ${opacity})`);
  }

  // Convert hex color to rgba
  if (color.startsWith("#")) {
    const bigint = parseInt(color.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // Default to original color if format is unexpected
  return color;
};

export const generateCSV = (headers, dataRows, filename = "data.csv") => {
  const csvContent = [headers, ...dataRows].map((e) => e.join(",")).join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createControls = (label, value, onChange, options = []) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel>{label}</InputLabel>
    <Select value={value} onChange={onChange} label={label}>
      {options.length > 0 ? (
        options.map((option) => (
          <MenuItem key={option.value || option} value={option.value || option}>
            {option.label || option}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
    </Select>
  </FormControl>
);

// Function to determine optimal text color (black/white) for contrast
export const getTextColor = (bgColor) => {
  const [r, g, b] = bgColor
    .replace(/^rgb\(|\)$/g, "")
    .split(",")
    .map(Number);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "black" : "white";
};
