import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
} from "@material-ui/core";
import { usePermittedVolumesOverTime } from "../hooks/usePermittedVolumesOverTime";
import BarChart from "../charts/BarChart";

const PermittedVolumesOverTimeChart = () => {
  const {
    data,
    isLoading,
    error,
    filters,
    setFilters,
    allValues,
    downloadCSV,
  } = usePermittedVolumesOverTime();

  const handleFilterChange = (filterName) => (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: event.target.value,
    }));
  };

  const controls = (
    <>
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Permit Status</InputLabel>
          <Select
            multiple
            value={filters.permit_status_group_name || []}
            onChange={handleFilterChange("permit_status_group_name")}
            label="Permit Status"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.permitStatusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox
                  checked={
                    filters.permit_status_group_name?.includes(status) || false
                  }
                />
                <ListItemText primary={status} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Type Group</InputLabel>
          <Select
            multiple
            value={filters.type_group || []}
            onChange={handleFilterChange("type_group")}
            label="Type Group"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.typeGroupOptions.map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox
                  checked={filters.type_group?.includes(type) || false}
                />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Aquifer Group</InputLabel>
          <Select
            multiple
            value={filters.aq_group_summary_name || []}
            onChange={handleFilterChange("aq_group_summary_name")}
            label="Aquifer Group"
            renderValue={(selected) => selected.join(", ")}
          >
            {allValues.aquiferGroupOptions.map((aqGroup) => (
              <MenuItem key={aqGroup} value={aqGroup}>
                <Checkbox
                  checked={
                    filters.aq_group_summary_name?.includes(aqGroup) || false
                  }
                />
                <ListItemText primary={aqGroup} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );

  return (
    <BarChart
      title="Permitted Volumes Over Time (MGal)"
      datasets={data.datasets}
      labels={data.labels}
      isLoading={isLoading}
      error={error}
      controls={controls}
      onDownloadCSV={downloadCSV}
    />
  );
};

export default PermittedVolumesOverTimeChart;
