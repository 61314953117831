import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { USE_QUERY_OPTIONS } from "../constants";

export const useWellCounts = () => {
  const [selectedCategory, setSelectedCategory] = useState("total_well_count");

  const { data, isLoading, error } = useQuery(
    "well-count-summary",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-dashboard-well-count-summary`
      );
      return response.data;
    },
    USE_QUERY_OPTIONS
  );

  const sortedData = useMemo(
    () => (data ? [...data].sort((a, b) => a.sort_order - b.sort_order) : []),
    [data]
  );

  return {
    data: sortedData,
    isLoading,
    error,
    selectedCategory,
    setSelectedCategory,
  };
};
