import React from "react";
import MaterialTable from "material-table";
import { scaleSequential } from "d3-scale";
import { interpolateReds } from "d3-scale-chromatic";
import { Box, Paper, Typography } from "@material-ui/core";
import { useLateMeterReadings } from "../hooks/useLateMeterReadings";
import { getTextColor } from "../utils";

const LateMeterReadingsTable = () => {
  const { data, isLoading, error } = useLateMeterReadings();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading data</Typography>;
  }

  // Determine min and max days_late for color ramp
  const daysLateValues = data.map((item) => item.days_late);
  const minDaysLate = Math.min(...daysLateValues);
  const maxDaysLate = Math.max(...daysLateValues);

  const colorScale = scaleSequential(interpolateReds).domain([
    minDaysLate,
    maxDaysLate,
  ]);

  const columns = [
    {
      title: "Permit",
      field: "grouping_name",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    { title: "Meter ID", field: "meter_id" },
    {
      title: "Days Late",
      field: "days_late",
      render: ({ days_late }) => {
        const backgroundColor = colorScale(days_late);
        const textColor = getTextColor(backgroundColor);

        return (
          <div
            style={{
              backgroundColor,
              borderRadius: "4px",
              padding: "4px",
              textAlign: "center",
              color: textColor,
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {days_late}
          </div>
        );
      },
    },
    {
      title: "Last Report",
      field: "c_year_c_month",
      render: (rowData) =>
        `${rowData.c_year}-${String(rowData.c_month).padStart(2, "0")}`,
    },
  ];

  return (
    <Paper style={{ padding: "16px" }} variant="outlined">
      <MaterialTable
        title="Late Meter Readings"
        columns={columns}
        data={data}
        options={{
          showTitle: false,
          search: false,
          paging: false,
          emptyRowsWhenPaging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: "#f5f5f5",
            fontWeight: "bold",
          },
        }}
        components={{
          Toolbar: () => (
            <Box
              display="flex"
              justifyContent="center"
              padding="10px 0 20px 0"
              bgcolor="#fff"
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Arial",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666",
                }}
              >
                Late Meter Readings
              </Typography>
            </Box>
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "No Late Meter Readings!",
          },
        }}
      />
    </Paper>
  );
};

export default LateMeterReadingsTable;
